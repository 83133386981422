import React from 'react';
import { isBrowser } from 'utils/is-browser';

interface RedirectProps {
    to: string;
}

const Redirect: React.FC<RedirectProps> = ({ to }) => {
    if (isBrowser) {
        window.location.href = to;
    }
    return <></>;
};

export default Redirect;
