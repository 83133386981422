/**

  Gatsby generates static HTML when we run `gatsby build` (build time React
  SSR). This HTML is then re-hydrated on the client when the page is first
  rendered by the user's browser. Thereafter the normal React reconciliation
  takes over.

  The first step of this process (build time React SSR) happens on a Node.js
  server, which does not have browser-specific APIs like `window`. So we need to
  either (a) check before calling if window is defined so that the code doesn't
  run when Gatsby is buliding, or (b) move the code into a useEffect hook, which
  ensures that the code doesn't run unless is in the browser.

  This variable implements option (a).

  This is safe and will not cause user visible impact since the HTML would be
  re-hydrated on the client anyway before the first rendered, where the check
  would detect a browser and allow the ignored code to run.
*/
export const isBrowser = typeof window !== 'undefined';
